var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card table-responsive"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center my-2"},[_c('span',{staticClass:"w-700"},[_vm._v("loans")]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentUser.role_id.id != 4),expression:"currentUser.role_id.id != 4"}],attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.passIdToState(0)}}},[_c('i',{staticClass:"fas fa-money-check-alt"}),_vm._v(" CREATE")])],1),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 offset-md-6"},[_c('v-text-field',{attrs:{"label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.loans,"search":_vm.search},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created_at).toISOString().slice(0, 10))+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('b',[_vm._v(_vm._s(item.lend_by.full_name))]),_vm._v(" "),_c('br'),_vm._v(" Account No:"+_vm._s(item.lend_by.account_id)+" "),_c('br'),_vm._v(" Phone:"+_vm._s(item.lend_by.mobile)+" "),_c('br')])]}},{key:"item.referee",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.lend_by.referee1))]),_c('p',[_vm._v(_vm._s(item.lend_by.referee2))])]}},{key:"item.employee",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('b',[_vm._v(_vm._s(item.handled_by.full_name))]),_vm._v(" "),_c('br'),_vm._v(" Account No:"+_vm._s(item.handled_by.account_id)+" "),_c('br'),_vm._v(" Phone:"+_vm._s(item.handled_by.mobile)+" "),_c('br')])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.tenor_type == 'monthly'),expression:"item.tenor_type == 'monthly'"}]},[_vm._v("Monthly")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.tenor_type == 'weekly'),expression:"item.tenor_type == 'weekly'"}]},[_vm._v("Weekly")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.tenor_type == 'daily'),expression:"item.tenor_type == 'daily'"}]},[_vm._v("Daily")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentUser.role_id.id != 4),expression:"currentUser.role_id.id != 4"}],staticClass:"btn btn-info btn-sm",on:{"click":function($event){return _vm.passIdToState(item.id)}}},[_c('i',{staticClass:"fas fa-edit"})])]}}])}),_c('loan-form-modal',{ref:"loan_form"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }