<template>
  <div class="card table-responsive">
    <div class="d-flex justify-content-between align-items-center my-2">
      <span class="w-700">Payments</span>
      <b-button
        variant="outline-primary"
        size="sm"
        @click="passIdToState(0)"
        v-show="currentUser.role_id.id != 4"
        ><i class="fas fa-money-check-alt"></i> CREATE</b-button
      >
    </div>
    <hr />
    <div class="row">
      <div class="col-12 col-md-6 offset-md-6">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
        ></v-text-field>
      </div>
    </div>

    <v-data-table :headers="headers" :items="payments" :search="search">
      <template v-slot:item.created_at="{ item }">
        {{ new Date(item.created_at).toISOString().slice(0, 10) }}
      </template>
      <template v-slot:item.action="{ item }">
        <b-button
          title="Print a Bill"
          :href="'/billing/' + item.id"
          target="_blank"
          class="btn btn-info btn-sm"
          ><i class="fas fa-print"></i
        ></b-button>
      </template>
    </v-data-table>
    <payment-form-modal ref="payment_form"></payment-form-modal>
  </div>
</template>
<script>
import ApiService from "@/services/api.service";
// import Swal from "sweetalert2";
export default {
  components: {
    paymentFormModal: () =>
      import(/* webpackChunkName: "payment-form" */ "./PaymentForm.Widget")
  },
  data() {
    return {
      payments: [],
      currentUser: {},
      search: "",
      headers: [
        { text: "TRANSACTION DATE", value: "created_at" },
        { text: "LOAN", value: "loan_id.reference_code" },
        { text: "EMPLOYEE", value: "handled_by.full_name" },
        { text: "LEND AMOUNT (Rs. LKR.)", value: "amount" },
        { text: "REMARK", value: "note" },
        { text: "ACTION", value: "action" }
      ]
    };
  },
  created() {
    if (this.$store.getters.currentUser != null) {
      this.currentUser = this.$store.getters.currentUser;
    }
    const user = this.$store.getters.currentUser;
    ApiService.get("payments").then(({ data }) => {
      if (user.role_id.id == 4) {
        this.payments = data.payments.filter(e => e.loan_id.lend_by == user.id);
      } else {
        this.payments = data.payments;
      }
    });
  },
  methods: {
    passIdToState(id) {
      this.$refs.payment_form.getIdFromChild(id);
    },
    callByComponent() {
      const user = this.$store.getters.currentUser;
      ApiService.get("payments").then(({ data }) => {
        if (user.role_id.id == 4)
          this.payments = data.payments.filter(
            e => e.loan_id.lend_by == user.id
          );
        else this.payments = data.payments;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
th {
  font-size: 14px;
  font-weight: 700;
}
td {
  font-size: 12px;
}
</style>
