<template>
  <div class="card table-responsive">
    <div class="d-flex justify-content-between align-items-center my-2">
      <span class="w-700">loans</span>
      <b-button
        variant="outline-primary"
        size="sm"
        @click="passIdToState(0)"
        v-show="currentUser.role_id.id != 4"
        ><i class="fas fa-money-check-alt"></i> CREATE</b-button
      >
    </div>
    <hr />
    <!-- <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Loan ID</th>
          <th>Customer Details</th>
          <th>Referee Details</th>
          <th>Handled By</th>
          <th>Amount</th>
          <th>Total Payment</th>
          <th>Total Paid</th>
          <th>Remaining Installments</th>
          <th>Remaining Payment</th>
          <th>Instalment Type</th>
          <th>Instalment</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in loans" :key="index">
          <td>{{ item.reference_code }}</td>
          <td>
            <p>
              <b>{{ item.lend_by.full_name }}</b> <br />
              Account No:{{ item.lend_by.account_id }} <br />
              Phone:{{ item.lend_by.mobile }} <br />
            </p>
          </td>
          <td>
            <p>{{ item.lend_by.referee1 }}</p>
            <p>{{ item.lend_by.referee2 }}</p>
          </td>
          <td>
            <p>
              <b>{{ item.handled_by.full_name }}</b> <br />
              Account No:{{ item.handled_by.account_id }} <br />
              Phone:{{ item.handled_by.mobile }} <br />
            </p>
          </td>
          <td>{{ item.amount }}</td>
          <td>{{ item.total_payment }}</td>
          <td>{{ item.paid_payments }}</td>
          <td>{{ item.remaining_payments.toFixed(2) }}</td>
          <td>
            <span v-show="item.tenor_type == 'monthly'">{{
              (item.emi * item.remaining_installments).toFixed(2)
            }}</span>
            <span v-show="item.tenor_type == 'weekly'">{{
              (item.emi * item.remaining_installments).toFixed(2)
            }}</span>
            <span v-show="item.tenor_type == 'daily'">{{
              (item.emi * item.remaining_installments).toFixed(2)
            }}</span>
          </td>
          <td>
            <span v-show="item.tenor_type == 'monthly'">Monthly</span>
            <span v-show="item.tenor_type == 'weekly'">Weekly</span>
            <span v-show="item.tenor_type == 'daily'">Daily</span>
          </td>
          <td>
            {{ item.emi }}
          </td>
          <td>
            <button
              class="btn btn-link"
              size="sm"
              v-show="currentUser.role_id.id != 4"
              @click="passIdToState(item.id)"
            >
              <i class="fas fa-edit"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table> -->
    <div class="row">
      <div class="col-12 col-md-6 offset-md-6">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
        ></v-text-field>
      </div>
    </div>

    <v-data-table :headers="headers" :items="loans" :search="search">
      <template v-slot:item.created_at="{ item }">
        {{ new Date(item.created_at).toISOString().slice(0, 10) }}
      </template>
      <template v-slot:item.customer="{ item }">
        <p>
          <b>{{ item.lend_by.full_name }}</b> <br />
          Account No:{{ item.lend_by.account_id }} <br />
          Phone:{{ item.lend_by.mobile }} <br />
        </p>
      </template>
      <template v-slot:item.referee="{ item }">
        <p>{{ item.lend_by.referee1 }}</p>
        <p>{{ item.lend_by.referee2 }}</p>
      </template>
      <template v-slot:item.employee="{ item }">
        <p>
          <b>{{ item.handled_by.full_name }}</b> <br />
          Account No:{{ item.handled_by.account_id }} <br />
          Phone:{{ item.handled_by.mobile }} <br />
        </p>
      </template>
      <template v-slot:item.type="{ item }">
        <span v-show="item.tenor_type == 'monthly'">Monthly</span>
        <span v-show="item.tenor_type == 'weekly'">Weekly</span>
        <span v-show="item.tenor_type == 'daily'">Daily</span>
      </template>
      <template v-slot:item.action="{ item }">
        <button
          class="btn btn-info btn-sm"
          v-show="currentUser.role_id.id != 4"
          @click="passIdToState(item.id)"
        >
          <i class="fas fa-edit"></i>
        </button>
      </template>
    </v-data-table>
    <loan-form-modal ref="loan_form"></loan-form-modal>
  </div>
</template>
<script>
import ApiService from "@/services/api.service";
import Swal from "sweetalert2";
export default {
  components: {
    loanFormModal: () =>
      import(/* webpackChunkName: "loan-form" */ "./LoanForm.Widget")
  },
  data() {
    return {
      loans: [],
      currentUser: {},
      search: "",
      headers: [
        { text: "TRANSACTION DATE", value: "created_at" },
        { text: "LOAN", value: "reference_code" },
        { text: "CUSTOMER", value: "customer" },
        { text: "REFEREE", value: "referee" },
        { text: "EMPLOYEE", value: "employee" },
        { text: "LEND AMOUNT (Rs. LKR.)", value: "amount" },
        {
          text: "TOTAL AMOUNT AFTER CALCULATE (Rs. LKR.)",
          value: "total_payment"
        },
        { text: "TOTAL PAID (Rs. LKR.)", value: "paid_payments" },
        { text: "REMAINING (Rs. LKR.)", value: "remaining_payments" },
        { text: "INSTALLMENT TYPE", value: "type" },
        { text: "INSTALLMENT", value: "emi" },
        { text: "ACTION", value: "action" }
      ]
    };
  },
  created() {
    if (this.$store.getters.currentUser != null)
      this.currentUser = this.$store.getters.currentUser;

    ApiService.get("loans").then(({ data }) => {
      if (this.currentUser.role_id.id == 4)
        this.loans = data.loans.filter(
          x => x.lend_by.id == this.currentUser.id
        );
      else this.loans = data.loans;
    });
  },
  methods: {
    passIdToState(id) {
      this.$refs.loan_form.getIdFromChild(id);
    },
    callByComponent() {
      ApiService.get("loans").then(({ data }) => {
        this.loans = data.loans;
      });
    },
    loanDelete(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          ApiService.delete("loans/" + item.id).then(({ data }) => {
            if (data.isSuccess) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
            } else {
              Swal.fire("Oops...!", "Something went wrong!", "error");
            }
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
th {
  font-size: 14px;
  font-weight: 700;
}
td {
  font-size: 12px;
}
</style>
