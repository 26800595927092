<template>
  <div class="container">
    <div class="d-flex flex-wrap">
      <Users
        v-show="currentUser.role_id.id == 1 || currentUser.role_id.id == 2"
        class="col-12 col-md-12 my-2 mx-1"
      ></Users>
      <Loans
        class="col-12 col-md-12 my-2"
        v-show="currentUser.role_id.id != 4 && currentUser.role_id.id != 3"
      ></Loans>
      <Payments class="col-12 col-md-12 my-2"></Payments>
      <Incomes
        class="col-12 col-md-6 my-2"
        v-show="currentUser.role_id.id != 4 && currentUser.role_id.id != 3"
      ></Incomes>
      <Expenses
        class="col-12 col-md-6 my-2"
        v-show="currentUser.role_id.id != 4 && currentUser.role_id.id != 3"
      ></Expenses>
      <Accounts
        v-show="currentUser.role_id.id == 1 || currentUser.role_id.id == 2"
        class="col-12 col-md-12 my-2 mx-1"
      ></Accounts>
    </div>
  </div>
</template>
<script>
import Users from "@/views/widgets/User.Widget";
import Loans from "@/views/widgets/Loan.Widget";
import Accounts from "@/views/widgets/Account.Widget";
import Incomes from "@/views/widgets/Income.Widget";
import Expenses from "@/views/widgets/Expense.Widget";
import Payments from "@/views/widgets/Payment.Widget";
export default {
  components: {
    Users,
    Loans,
    Accounts,
    Incomes,
    Expenses,
    Payments
  },
  data() {
    return {
      currentUser: {}
    };
  },
  created() {
    if (this.$store.getters.currentUser != null)
      this.currentUser = this.$store.getters.currentUser;
  }
};
</script>
<style lang="scss">
.card {
  box-shadow: 2px 2px 10px 1px #d3d3d3;
}
</style>
