<template>
  <div class="card table-responsive">
    <div class="d-flex justify-content-between align-items-center my-2">
      <span class="w-700">Users</span>
      <b-button variant="outline-primary btn-sm" @click="passIdToState(0)"
        ><i class="fas fa-user-plus"></i> ADD</b-button
      >
    </div>
    <hr />

    <div class="row">
      <div class="col-12 col-md-6 offset-md-6">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
        ></v-text-field>
      </div>
    </div>

    <v-data-table :headers="headers" :items="users" :search="search">
      <template v-slot:item.referee="{ item }">
        <p>{{ item.referee1 }}</p>
        <p>{{ item.referee2 }}</p>
      </template>
      <template v-slot:item.action="{ item }">
        <button
          class="btn btn-warning my-1 btn-sm"
          title="Edit user account"
          @click="passIdToState(item.id)"
        >
          <i class="fas fa-user-edit"></i>
        </button>
        <button
          class="btn btn-danger btn-sm"
          title="Delete user account"
          @click="userDelete(item)"
        >
          <i class="fas fa-user-times"></i>
        </button>
      </template>
    </v-data-table>
    <user-form-modal ref="user_form"></user-form-modal>
  </div>
</template>
<script>
import ApiService from "@/services/api.service";
import Swal from "sweetalert2";
export default {
  components: {
    UserFormModal: () =>
      import(/* webpackChunkName: "user-form" */ "./UserForm.Widget")
  },
  data() {
    return {
      users: [],
      search: "",
      headers: [
        { text: "FULL NAME", value: "full_name" },
        { text: "NIC", value: "nic" },
        { text: "ACCOUNT ID", value: "account_id" },
        { text: "ADDRESS", value: "permanent_address" },
        { text: "EMAIL", value: "email" },
        { text: "MOBILE", value: "mobile" },
        { text: "REFEREE", value: "referee" },
        { text: "ACTION", value: "action" }
      ]
    };
  },
  created() {
    ApiService.get("auth").then(({ data }) => {
      this.users = data.users;
    });
  },
  methods: {
    passIdToState(id) {
      // this.$store.commit("user_id", id);
      this.$refs.user_form.getIdFromChild(id);
    },
    callByComponent() {
      ApiService.get("auth").then(({ data }) => {
        this.users = data.users;
      });
    },
    userDelete(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          ApiService.delete("auth/" + item.id).then(({ data }) => {
            if (data.isSuccess) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
            } else {
              Swal.fire("Oops...!", "Something went wrong!", "error");
            }
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
th {
  font-size: 14px;
  font-weight: 700;
}
td {
  font-size: 12px;
}
</style>
