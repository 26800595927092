<template>
  <div class="card table-responsive">
    <div class="d-flex justify-content-between align-items-center my-2">
      <span class="w-700">Expenses</span>
      <b-button variant="outline-primary" size="sm" @click="passIdToState(0)"
        ><i class="fas fa-cash-register"></i> CREATE</b-button
      >
    </div>
    <hr />
    <div class="row">
      <div class="col-12 col-md-6 offset-md-6">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
        ></v-text-field>
      </div>
    </div>

    <v-data-table
      :headers="headers"
      :items="transactions"
      :search="search"
      class="error"
    >
      <template v-slot:item.created_at="{ item }">
        {{ new Date(item.created_at).toISOString().slice(0, 10) }}
      </template></v-data-table
    >
    <expense-form-modal ref="expense_form"></expense-form-modal>
  </div>
</template>
<script>
import ApiService from "@/services/api.service";
export default {
  components: {
    expenseFormModal: () =>
      import(/* webpackChunkName: "expense-form" */ "./ExpenseForm.Widget")
  },
  data() {
    return {
      transactions: [],
      search: "",
      headers: [
        { text: "TRANSACTION DATE", value: "created_at" },
        { text: "REF CODE", value: "reference_code" },
        { text: "AMOUNT (Rs. LKR.)", value: "amount" },
        { text: "REMARK", value: "note" }
      ]
    };
  },
  created() {
    ApiService.get("accounts/" + 0).then(({ data }) => {
      this.transactions = data.transactions;
    });
  },
  methods: {
    passIdToState(id) {
      this.$refs.expense_form.getIdFromChild(id);
    },
    callByComponent() {
      ApiService.get("accounts/" + 0).then(({ data }) => {
        this.transactions = data.transactions;
      });
    }
  }
};
</script>
<style lang="scss">
th {
  font-size: 14px;
  font-weight: 700;
}
td {
  font-size: 12px;
}
</style>
