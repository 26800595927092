<template>
  <div class="card table-responsive">
    <div class="d-flex justify-content-between align-items-center my-2">
      <span class="w-700">Account Summary</span>
    </div>
    <hr />
    <!-- <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Transaction Date</th>
          <th>Reference Code</th>
          <th>Amount</th>
          <th>Note</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        <tr
          :class="{
            'table-danger': item.transaction_type == 0,
            'table-success': item.transaction_type == 1
          }"
          v-for="(item, index) in transactions"
          :key="index"
        >
          <td>{{ new Date(item.created_at).toISOString().slice(0,10)}}</td>
          <td>{{ item.reference_code }}</td>
          <td>{{ item.amount }}</td>
          <td>{{ item.note }}</td>
          <td>{{ item.transaction_type == 1 ? "CR" : "DR" }}</td>
        </tr>
      </tbody>
    </table> -->
    <div class="row">
      <div class="col-12 col-md-6 offset-md-6">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
        ></v-text-field>
      </div>
    </div>

    <v-data-table :headers="headers" :items="transactions" :search="search">
      <template v-slot:item.created_at="{ item }">
        {{ new Date(item.created_at).toISOString().slice(0, 10) }}
      </template>
      <template v-slot:item.transaction_type="{ item }">
        {{ item.transaction_type == 1 ? "CREDIT" : "DEBIT" }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import ApiService from "@/services/api.service";
export default {
  data() {
    return {
      transactions: [],
      search: "",
      headers: [
        { text: "TRANSACTION DATE", value: "created_at" },
        { text: "REF CODE", value: "reference_code" },
        { text: "AMOUNT (Rs. LKR.)", value: "amount" },
        { text: "REMARK", value: "note" },
        { text: "TRANSACTION TYPE", value: "transaction_type" }
      ]
    };
  },
  created() {
    ApiService.get("accounts").then(({ data }) => {
      this.transactions = data.transactions;
    });
  }
};
</script>
